import { Route, Routes } from "react-router-dom";
import Telemetry from "./container/Telemetry";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/telemetry" element={<Telemetry />} />
    </Routes>
  );
};

export default Router;
