import { useState, useContext, useEffect } from "react";
import { Row, Col, Card } from "antd";
import BreadcrumbsTelemetry from "../component/BreadcrumbsTelemetry";
import {
  useLoadingGif,
  useScreenOptions,
  useTelemetry,
  TenantIdContext,
  useDevice,
  useLocation,
  ACTIVE,
  NotificationContext,
  useIntl,
  exportToExcel,
  getRequiredDateFormat,
} from "@datwyler/mfe-shared-components";
import Header from "../component/Header";
import { FILTERDEFAULTS } from "../utils/constants";
import TelemetryTable from "../component/TelemetryTable";
import Filters from "../component/Filters";

const Telemetry = () => {
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { isMobileScreen } = useScreenOptions();
  const { tenantId }: any = useContext(TenantIdContext);
  const { openNotification }: any = useContext(NotificationContext);
  const intl = useIntl();
  const [filters, setFilters] = useState(FILTERDEFAULTS);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { isFetchTelemetryLoading, fetchTelemetryData, fetchTelemetry } =
    useTelemetry();
  const {
    isFetchTelemetryLoading: isFetchTelemetryForExportLoading,
    fetchTelemetryData: fetchTelemetryForExportData,
    fetchTelemetry: fetchTelemetryForExport,
  } = useTelemetry();

  const {
    isFetchLoading: isFetchDeviceLoading,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();

  const {
    isFetchLoading: isFetchLocationLoading,
    fetchLocationData,
    fetchLocations,
  } = useLocation();

  useEffect(() => {
    setIsLoading(
      isFetchTelemetryLoading ||
        isFetchLocationLoading ||
        isFetchTelemetryForExportLoading ||
        false
    );
  }, [
    isFetchTelemetryLoading,
    isFetchLocationLoading,
    isFetchTelemetryForExportLoading,
  ]);

  useEffect(() => {
    if (tenantId) {
      fetchLocations({
        variables: { tenantId: tenantId },
      });
    }
  }, [tenantId]);

  useEffect(() => {
    if (fetchTelemetryForExportData?.telemetries?.telemetries) {
      exportToExcel(
        getExcelData(fetchTelemetryForExportData.telemetries.telemetries),
        intl.formatMessage({ id: "excel_filename_telemetry" })
      );
    }
  }, [fetchTelemetryForExportData]);

  useEffect(() => {
    if (
      fetchTelemetryData?.telemetries?.telemetries &&
      fetchTelemetryData?.telemetries?.telemetries.length < 1
    )
      openNotification({
        type: "warning",
        description: intl.formatMessage({ id: "no_data" }),
        placement: "topRight",
      });
  }, [fetchTelemetryData]);

  useEffect(() => {
    if (
      filters.startTime &&
      filters.devices.length > 0 &&
      filters.parameters.length > 0
    )
      fetchData();
  }, [pageNumber, rowsPerPage, filters]);

  const fetchData = () => {
    const startTime = filters.startTime;
    const deviceIds = filters.devices;
    let filtersToSend = "";
    filters.parameters.forEach((value, index) => {
      if (index === 0) filtersToSend = `name:${value}`;
      else filtersToSend = filtersToSend + `|name:${value}`;
    });

    fetchTelemetry({
      variables: {
        tenantId: tenantId,
        startTime: startTime,
        endTime: startTime + 86400 - 1, // +24hours
        deviceIds: deviceIds,
        page: { number: pageNumber, size: rowsPerPage },
        sort: ["time,desc"],
        filter: [filtersToSend],
      },
    });
  };

  const getDevices = (sites) => {
    if (tenantId) {
      const filtersToSend = [`status:${ACTIVE}`];
      let sitesToSend = "";
      sites.forEach((site, index) => {
        if (index === 0) sitesToSend = sitesToSend + `siteId:` + site;
        else sitesToSend = sitesToSend + `|siteId:` + site;
      });
      if (sitesToSend) filtersToSend.push(sitesToSend);

      fetchDevices({
        variables: {
          tenantId: tenantId,
          page: { number: 0, size: 999999 },
          filter: filtersToSend,
        },
      });
    }
  };

  const fetchDataForExcel = () => {
    // check if filters are not entered
    if (
      !filters.startTime ||
      filters.devices.length < 1 ||
      filters.parameters.length < 1
    ) {
      openNotification({
        type: "error",
        description: intl.formatMessage({ id: "no_filters" }),
        placement: "topRight",
      });
      return;
    }

    const startTime = filters.startTime;
    const deviceIds = filters.devices;
    let filtersToSend = "";
    filters.parameters.forEach((value, index) => {
      if (index === 0) filtersToSend = `name:${value}`;
      else filtersToSend = filtersToSend + `|name:${value}`;
    });

    fetchTelemetryForExport({
      variables: {
        tenantId: tenantId,
        startTime: startTime,
        endTime: startTime + 86400 - 1, // +24hours
        deviceIds: deviceIds,
        page: { number: 0, size: 999999 }, // send a huge number
        sort: [],
        filter: [filtersToSend],
      },
    });
  };

  const getExcelData = (telemetries) => {
    const excelData = [];

    telemetries.forEach((telemetry) => {
      const deviceDetails = fetchDeviceData?.devices?.devices?.find(
        (device) => device.id === telemetry.device.id
      );
      const siteName = deviceDetails.site?.name || "";
      const deviceName = telemetry.device?.name || "";
      let value = intl.formatMessage({ id: telemetry.value?.toLowerCase() })
        ? intl.formatMessage({ id: telemetry.value?.toLowerCase() })
        : telemetry.value;

      if (telemetry.unit) value = value + " " + telemetry.unit;

      const row = {};
      row[intl.formatMessage({ id: "site" })] = siteName || "-";
      row[intl.formatMessage({ id: "device" })] = deviceName || "-";
      row[intl.formatMessage({ id: "parameter" })] =
        intl.formatMessage({ id: telemetry.name }) || "-";
      (row[intl.formatMessage({ id: "value" })] = value),
        (row[intl.formatMessage({ id: "timestamp" })] = telemetry.time
          ? getRequiredDateFormat(telemetry.time, "DD/MM/YYYY HH:mm")
          : "-");

      excelData.push(row);
    });
    return excelData;
  };

  return (
    <Row>
      <LoadingGif />
      {!isMobileScreen && (
        <Col span={24}>
          <BreadcrumbsTelemetry />
        </Col>
      )}
      <Col span={24}>
        <Card>
          <Header
            fetchDataForExcel={fetchDataForExcel}
            locations={fetchLocationData?.locations}
            setPageNumber={setPageNumber}
            filters={filters}
            setFilters={setFilters}
            getDevices={getDevices}
            devices={fetchDeviceData?.devices?.devices}
            isFetchDeviceLoading={isFetchDeviceLoading}
          />
          {!isMobileScreen && (
            <Filters
              locations={fetchLocationData?.locations}
              setPageNumber={setPageNumber}
              filters={filters}
              setFilters={setFilters}
              getDevices={getDevices}
              devices={fetchDeviceData?.devices?.devices}
              isFetchDeviceLoading={isFetchDeviceLoading}
            />
          )}
          {fetchTelemetryData?.telemetries?.telemetries && (
            <TelemetryTable
              data={fetchTelemetryData?.telemetries}
              locations={fetchLocationData?.locations}
              devices={fetchDeviceData?.devices?.devices}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Telemetry;
