import React, { useState } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  GraphQLApiProvider,
  NotificationProvider,
  DarkModeProvider,
  useLocale,
  TranslationProvider,
} from "@datwyler/mfe-shared-components";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <TranslationProvider locale={locale}>
        <NotificationProvider>
          <GraphQLApiProvider uri={props.GRAPHQL_URL}>
            <BrowserRouter>
              <DarkModeProvider>
                <App />
              </DarkModeProvider>
            </BrowserRouter>
          </GraphQLApiProvider>
        </NotificationProvider>
      </TranslationProvider>
    </React.StrictMode>
  );
}
